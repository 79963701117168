<template>
  <div class="p-0">
    <div class="row text-left">
      <div class="col-12 p-3">
        <div class="card" style="max-width: 800px; margin: 0 auto !important">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-sm-6">
                <label><b-icon-calendar /> Data Agenda</label>
                <div class="row">
                  <div class="col-6 pr-1">
                    <b-input
                      type="date"
                      style="padding: 3px"
                      v-model="filtros.periodo.dt_i"
                    />
                  </div>
                  <div class="col-6 pl-1">
                    <b-input
                      type="date"
                      style="padding: 3px"
                      v-model="filtros.periodo.dt_f"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <label><b-icon-calendar /> Data Venda</label>
                <div class="row">
                  <div class="col-6 pr-1">
                    <b-input
                      type="date"
                      style="padding: 3px"
                      v-model="filtros.periodo_vnd.dt_i"
                    />
                  </div>
                  <div class="col-6 pl-1">
                    <b-input
                      type="date"
                      style="padding: 3px"
                      v-model="filtros.periodo_vnd.dt_f"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 mt-3">
                <select-empresa-clinica
                  :multiple="true"
                  @empresa="empresaSelect"
                  :withConsultorio="false"
                />
              </div>
              <div class="col-12 col-sm-6 mt-3">
                <label>Valor Mínimo Venda</label>
                <money
                  class="form-control"
                  v-model="filtros.valor_venda"
                  v-bind="moneyMask"
                ></money>
              </div>
              <div class="col-12 col-sm-6 mt-1">
                <label>Tipo de Relatório</label>
                <b-select
                  :options="[
                    'Detalhado',
                    'Resumido',
                    'Somente Total',
                    'Resumido por clínica',
                    'Resumido por dia',
                  ]"
                  v-model="filtros.tipo_relatorio"
                />
              </div>
              <div class="col-12 col-sm-6 mt-1">
                 <b-checkbox switch v-model="filtros.retorno" class="mt-4">Incluir Retornos</b-checkbox>
              </div>

              <div class="col-12 mt-3">
                <b-btn variant="info" @click="buscar" :disabled="loading" block>
                  <span v-if="loading">
                    <b-spinner small size="sm" /> aguarde...
                  </span>
                  <span v-if="!loading"> <b-icon-search /> Buscar </span>
                </b-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="max-width: 800px; margin: 0 auto">
      <div class="text-center" v-if="printNow">
        <b-btn @click="imprimirDetalhes"><b-icon-printer /> Imprimir</b-btn>
      </div>

      <PrintComponent
        v-if="printNow"
        :printNow="true"
        :show="showDetails"
        @done="doneprintNow"
        :titulo="`Relatório de Aproveitamento Consultas X Venda`"
        :filtros="detalhesHeader"
        :orientationPDF="'portrait'"
      >
        <div class="row">
          <div
            class="col-12"
            v-for="ag in lista"
            :key="`ag_${ag.clinica}_${ag.data}_${Math.random(12123121)}`"
          >
            <table
              class="table table-sm table-stiped"
              v-if="
                filtros &&
                  filtros.tipo_relatorio &&
                  (filtros.tipo_relatorio == 'Detalhado' ||
                    filtros.tipo_relatorio == 'Resumido')
              "
              style="font-size: 10px"
            >
              <thead>
                <tr class="text-center bg-light">
                  <th>
                    <b>Clínica</b>
                  </th>
                  <th>Data</th>
                  <th>Consultas</th>
                  <th>Retornos</th>
                  <th>Compareceram</th>
                  <th>Precisaram</th>
                  <th>Vendas</th>
                  <th>Valor Vendas</th>
                  <th>Venda Média</th>
                  <th>Aproveitamento</th>
                </tr>
                <tr class="text-center">
                  <td>{{ ag.clinica }}</td>
                  <td>{{ ag.data | moment("DD/MM/YYYY") }}</td>
                  <td>{{ ag.consultas || "" }}</td>
                  <td>{{ ag.retornos || "" }}</td>
                  <td>{{ ag.compareceram }}</td>
                  <td>
                    {{ ag.precisaram || "" }}
                  </td>
                  <td>
                    {{ ag.vendas.qtd || "" }}
                  </td>
                  <td>
                    {{ ag.vendas.total | currency }}
                  </td>

                  <td>
                    {{ ag.vendas.media | currency }}
                  </td>

                  <td>{{ ag.aproveitamento | currency }} %</td>
                </tr>
              </thead>
              <tbody
                v-if="
                  filtros.tipo_relatorio &&
                    filtros.tipo_relatorio == 'Detalhado'
                "
              >
                <tr>
                  <td colspan="989" class="text-left pl-3 pr-3">
                    <div class="text-center">Lista de Pacientes / Vendas</div>
                    <hr class="mt-1 mb-1" />
                    <table
                      class="table table-sm table-striped"
                      style="font-size: 8px"
                    >
                      <thead>
                        <tr>
                          <th style="width: 40%">Paciente</th>
                          <th style="width: 20%">Resultado</th>
                          <th style="width: 40%">Vendas</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="h in ag.lista">
                          <tr
                            v-if="h.paciente && h.paciente != ''"
                            :key="
                              `hora_lista_row${h.sigla}_${Math.random(
                                 
                              )}`
                            "
                          >
                            <td class="d-flex justify-content-between">
                              <span>{{ h.paciente }}</span>
                              <small style="color:red">{{ h.retorno==1?'[Retorno]':'' }}</small>

                            </td>
                            <td>
                              {{ h.tipo == "Oftalmo" ? "Precisou" : h.tipo }}
                            </td>
                            <td class="">
                              <div
                                v-if="!h.vendas || h.vendas.length <= 0"
                              ></div>
                              <div v-else>
                                <div
                                  v-for="v in h.vendas"
                                  :key="
                                    `lin_venda_${v.cod_venda}${
                                      v.loja
                                    }_${Math.random(2323232)}`
                                  "
                                >
                                  {{ v.loja }} - {{ v.cod_venda }} -

                                  {{
                                    [
                                      "",
                                      "A",
                                      "B",
                                      "C",
                                      "D",
                                      "E",
                                      "F",
                                      "G",
                                      "H",
                                      "I",
                                    ][v.cod_receita]
                                  }}
                                  - R$ {{ v.vl_total | currency }}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!--------------TOTALIZADORES ------------------>
          <div class="col-12 bg-light">
            <label>Total</label>
            <table class="table table-sm table-stiped" style="font-size: 10px">
              <thead>
                <tr class="text-center bg-light">
                  <th>
                    <b></b>
                  </th>
                  <th></th>
                  <th>Consultas</th>
                  <th>Retornos</th>
                  <th>Compareceram</th>
                  <th>Precisaram</th>
                  <th>Vendas</th>
                  <th>Valor Vendas</th>
                  <th>Venda Média</th>
                  <th>Aproveitamento</th>
                </tr>
                <tr class="text-center">
                  <td></td>
                  <td></td>
                  <td>{{ tt.consultas || "--" }}</td>
                  <td>{{ tt.retornos || "--" }}</td>
                  <td>{{ tt.compareceram || "--" }}</td>
                  <td>
                    {{ tt.precisaram || "--" }}
                  </td>
                  <td>
                    {{ tt.vendas.qtd || "--" }}
                  </td>
                  <td>
                    {{ tt.vendas.total | currency }}
                  </td>

                  <td>
                    {{ tt.vendas.media | currency }}
                  </td>

                  <td>{{ tt.aproveitamento | currency }} %</td>
                </tr>
              </thead>
            </table>
          </div>

          <!-----------Resumo por Clinica --------------->
          <div
            class="col-12"
            v-if="
              filtros &&
                filtros.tipo_relatorio &&
                (filtros.tipo_relatorio !== 'Somente Total' && filtros.tipo_relatorio !== 'Somente por dia')
            "
          >
            <hr />
            <h5>Resumo por Clínica</h5>
            <table class="table table-striped " style="font-size:9px">
              <thead>
                <tr>
                  <th>Clínica</th>
                  <th>Consultas</th>
                  <th>Retornos</th>
                  <th>Compareceram</th>
                  <th>Precisaram</th>
                  <th>Vendas</th>
                  <th>Valor Vendas</th>
                  <th>Valor Média</th>
                  <th>Aproveitamento</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="p in resumoClinica"
                  :key="`tr_r_clin_${p.clinica}+${Math.random()}`"
                >
                  <td>{{ p.clinica }}</td>
                  <td>{{ p.tt.consultas || "--" }}</td>
                  <td>{{ p.tt.retornos || "--" }}</td>
                  <td>{{ p.tt.compareceram || "--" }}</td>
                  <td>{{ p.tt.precisaram || "--" }}</td>
                  <td>{{ p.tt.vendas.qtd || "--" }}</td>
                  <td>{{ p.tt.vendas.total || 0 | currency }}</td>
                  <td>{{ p.tt.vendas.media || 0 | currency}}</td>
                  <td>{{ p.tt.aproveitamento || 0 | currency }}%</td>
                </tr>
              </tbody>
            </table>
          </div>



   <!-----------Resumo por Data --------------->
          <div
            class="col-12"
            v-if="
              filtros &&
                filtros.tipo_relatorio &&
                (filtros.tipo_relatorio !== 'Somente Total' && filtros.tipo_relatorio !== 'Somente por clinica')
            "
          >
            <hr />
            <h5>Resumo por Data</h5>
            <table class="table table-striped " style="font-size:9px">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Consultas</th>
                  <th>Retornos</th>
                  <th>Compareceram</th>
                  <th>Precisaram</th>
                  <th>Vendas</th>
                  <th>Valor Vendas</th>
                  <th>Valor Média</th>
                  <th>Aproveitamento</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="p in resumoDia"
                  :key="`tr_r_clin_${p.clinica}+${Math.random()}`"
                >
                  <td>{{ p.dia | moment("DD/MM/YYYY") }}</td>
                  <td>{{ p.tt.consultas || "--" }}</td>
                  <td>{{ p.tt.retornos || "--" }}</td>
                  <td>{{ p.tt.compareceram || "--" }}</td>
                  <td>{{ p.tt.precisaram || "--" }}</td>
                  <td>{{ p.tt.vendas.qtd || "--" }}</td>
                  <td>{{ p.tt.vendas.total || 0 | currency }}</td>
                  <td>{{ p.tt.vendas.media || 0 | currency}}</td>
                  <td>{{ p.tt.aproveitamento || 0 | currency }}%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </PrintComponent>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import SelectEmpresaClinica from "../../components/common/SelectEmpresaClinica.vue";
import RelatoriosLib from "../../libs/RelatoriosLib";
import PrintComponent from "../../components/Print/PrintComponent.vue";

export default {
  components: {
    SelectEmpresaClinica,
    PrintComponent,
  },
  props: {},
  mounted() {},
  data() {
    return {
      detalhesHeader: "",
      showDetails: true,
      printNow: false,
      filtros: {
        empresas: [],
        periodo: {
          dt_i: moment().format("YYYY-MM-DD"),
          dt_f: moment().format("YYYY-MM-DD"),
        },
        periodo_vnd: {
          dt_i: moment().format("YYYY-MM-DD"),
          dt_f: moment().format("YYYY-MM-DD"),
        },
        usuarios: [],
        pacientes: null,
        medicos: [],
        especialidades: [],
        valor_venda: 0,
        tipo_relatorio: "Detalhado",
        retorno: false,
      },
      lista: [],
      loading: false,
      tt: {
        precisaram: 0,
        consultas: 0,
        vendas: {
          qtd: 0,
          total: 0,
          media: 0,
        },
        aproveitamento: 0, 
      },
      resumoClinica: [],
      resumoDia: [],
    };
  },
  watch: {},
  computed: {},
  methods: {
    imprimirDetalhes() {
      this.printNow = false;
      this.showDetails = false;
      setTimeout(() => {
        this.printNow = true;
      }, 100);
    },
    doneprintNow() {
      this.showDetails = true;
    },
    empresaSelect(emp) {
      this.filtros.empresas = emp.map((i) => {
        return { id: i.value.id, sigla: i.value.razao_social };
      });
    },
    async buscar() {
      this.tt = {
        precisaram: 0,
        consultas: 0,
        compareceram: 0,
        retornos: 0,
        vendas: {
          qtd: 0,
          total: 0,
          media: 0,
        },
        aproveitamento: 0,
      };

      this.resumoDia = [];
      this.resumoClinica = [];

      this.loading = true;
      this.printNow = false;
      this.showDetails = true;
      this.detalhesHeader = `Consultas ${moment(
        this.filtros.periodo.dt_i
      ).format("DD/MM/YYYY")} até ${moment(this.filtros.periodo.dt_f).format(
        "DD/MM/YYYY"
      )}`;
      this.detalhesHeader += ` | Vendas ${moment(
        this.filtros.periodo_vnd.dt_i
      ).format("DD/MM/YYYY")} até ${moment(
        this.filtros.periodo_vnd.dt_f
      ).format("DD/MM/YYYY")}`;
      if (this.filtros.valor_venda && this.filtros.valor_venda > 0) {
        this.detalhesHeader += ` | A partir de: ${this.formatFloatToReal(
          this.filtros.valor_venda
        )}`;
      }
      this.$nextTick();

      setTimeout(async () => {
        this.lista = (
          await RelatoriosLib.RelatorioAproveitamentoOTC(this.filtros)
        ).data;
        console.log(123, this.lista);
        for (let l of this.lista) {
          this.tt.precisaram += l.precisaram ? l.precisaram : 0;
          this.tt.compareceram += l.compareceram ? l.compareceram : 0;
          this.tt.retornos += l.retornos ? l.retornos : 0;
          this.tt.consultas += l.consultas ? l.consultas : 0;
          this.tt.vendas.total +=
            l.vendas && l.vendas.total ? l.vendas.total : 0;
          this.tt.vendas.qtd += l.vendas && l.vendas.qtd ? l.vendas.qtd : 0;
        }
        this.tt.vendas.media = this.tt.vendas.total / this.tt.vendas.qtd;
        this.tt.aproveitamento =
          (this.tt.vendas.qtd / this.tt.precisaram) * 100;

        this.resumoClinica = this.lista.sort((a, b) => {
          return moment(b.data).isBefore(a.data)?1:-1
        }).reduce((ret, vl) => {
          let has = ret.find((x) => x.clinica == vl.clinica);
          console.log("has", has);
          if (!has) {
            ret.push({
              clinica: vl.clinica,
              tt: {
                precisaram: vl.precisaram || 0,
                compareceram: vl.compareceram || 0,
                consultas: vl.consultas || 0,
                retornos: vl.retornos || 0,
                vendas: {
                  qtd: (vl.vendas && vl.vendas.qtd ? vl.vendas.qtd : 0) || 0,
                  total:
                    (vl.vendas && vl.vendas.total ? vl.vendas.total : 0) || 0,
                  media: 0,
                },
                aproveitamento: 0,
              },
            });
          } else {
            has.tt.precisaram += vl.precisaram || 0;
            has.tt.compareceram += vl.compareceram || 0;
            has.tt.consultas += vl.consultas || 0;
            has.tt.retornos += vl.retornos || 0;
            has.tt.vendas.qtd +=
              (vl.vendas && vl.vendas.qtd ? vl.vendas.qtd : 0) || 0;
            has.tt.vendas.total +=
              (vl.vendas && vl.vendas.total ? vl.vendas.total : 0) || 0;
            has.tt.vendas.media +=
              (has.tt.vendas.total || 0) / (has.tt.vendas.qtd || 1);
            has.tt.aproveitamento +=
              ((has.tt.vendas.qtd || 0) / (has.tt.precisaram || 1)) * 100;
          }

          return ret;
        }, []);
        await this.$nextTick();
              this.resumoDia = this.lista.reduce((ret, vl) => {
          let has = ret.find((x) => x.dia == vl.data);
          console.log("has", has);
          if (!has) {
            ret.push({
              dia: vl.data,
              tt: {
                precisaram: vl.precisaram || 0,
                compareceram: vl.compareceram || 0,
                consultas: vl.consultas || 0,
                retornos: vl.retornos || 0,
                vendas: {
                  qtd: (vl.vendas && vl.vendas.qtd ? vl.vendas.qtd : 0) || 0,
                  total:
                    (vl.vendas && vl.vendas.total ? vl.vendas.total : 0) || 0,
                  media: 0,
                },
                aproveitamento: 0,
              },
            });
          } else {
            has.tt.precisaram += vl.precisaram || 0;
            has.tt.compareceram += vl.compareceram || 0;
            has.tt.consultas += vl.consultas || 0;
            has.tt.retornos += vl.retornos || 0;
            has.tt.vendas.qtd +=
              (vl.vendas && vl.vendas.qtd ? vl.vendas.qtd : 0) || 0;
            has.tt.vendas.total +=
              (vl.vendas && vl.vendas.total ? vl.vendas.total : 0) || 0;
            has.tt.vendas.media +=
              (has.tt.vendas.total || 0) / (has.tt.vendas.qtd || 1);
            has.tt.aproveitamento +=
              ((has.tt.vendas.qtd || 0) / (has.tt.precisaram || 1)) * 100;
          }

          return ret;
        }, []);
        await this.$nextTick();
        this.printNow = true;
        this.loading = false;
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped></style>
