<template>
  <div>

    <div class="row text-">
      <div class="col">
        <b-form-group >
          <label><b-icon-shop/> Clínica</label>
  
          <v-select :disabled="disabled" v-model="empresa" :options="empresas" :multiple="multiple" >
             <template #option="item">
                  <small class="font-sm" >{{
                    item.label
                  }}</small>
                </template>
          </v-select>
        </b-form-group>
      </div>
      <!-- <div class="col" v-if="comClinica">
        <b-form-group label="Clínica">
          <b-input disabled v-if="!empresa">Selecione uma empresa</b-input>
          <v-select v-model="clinica" :options="clinicas" />
        </b-form-group>
      </div> -->
    </div>
    
  </div>
</template>

<script>
import EmpresaLib from "../../libs/EmpresasLib";
// import ClinicasLib from "../../libs/ClinicasLib";
export default {
  components: {},
  props: {
    comClinica: {
      type: Boolean,
      default: true,
    },
    required:{
        type: Boolean,
        default:true
    },
    autoBlock: {
      type: Boolean,
      default: true 
    },
    clinicaInicial:{
      type: Boolean,
      default: false
    },
    multiple:{
      type: Boolean,
      default:false
    },
    withConsultorio:{
      type:Boolean,
      default:false,
    },
    clinicaInicialID: {
      type: Number,
      default:0
    }
  },
  async mounted() {
    await this.carregar();
    if (this.clinicaInicialID > 0) {
      console.log("find emp", this.clinicaInicialID);
      this.empresa = this.empresas.find(x => x.value.id === this.clinicaInicialID);
      console.log("finded emp", this.empresa);

    }
    // this.empresa = {value: this.user.clinica_id}
  },
  data() {
    return {
      empresa: null,
      empresas: [],
      clinicasDisponiveis: [],
      clinicas: [],
      clinica:null,
      
     
    };
  },
  watch: {
      empresa:function(){
          if(this.required && (!this.empresa || !this.empresa.value && !this.user.isMedico) && this.clinicaInicial){
            //console.log('AAAAAAAAAAAA ->',this.user.clinica_id);
              this.empresa = this.empresas.find(e=>e.value.id==(this.user.clinica_id?this.user.clinica_id:this.user.empresa_id)) || this.empresas[0]
      
          }
          // this.clinicas = this.clinicasDisponiveis.filter(x=>x.empresa_id == this.empresa.value.id).map(r=>{
          //     return {value:r, label: r.sigla}
          // });
          // this.clinica = this.clinicas[0];
          if(this.multiple){
            this.$emit('empresa',this.empresa)            
          }else{
            this.$emit('empresa',this.empresa?.value)
          }
      },
     
  },
  computed: {
    // user(){
    //   return this.$store.state.auth.user
    // },
    disabled(){
      return this.autoBlock && ['secretaria'].includes(this.user.perfil)
    }
  },
  methods: {
    async carregar() {
      // this.clinicasDisponiveis = await ClinicasLib.get();

      this.empresas = (await EmpresaLib.getEmpresasGrupoAPI(false)) || []
      
      this.empresas = this.empresas.map((emp) => {
        return {
          value: emp,
          label: emp.fantasia + " (" + emp.sigla + ")"
        };
      });
      if(this.withConsultorio){
        this.empresas.push({
          value: {id:-2, razao_social: "CONSULTÓRIO MÉDICO"},
          label: "CONSULTÓRIO MÉDICO"
        })
      }
     if (!this.user.isMedico && this.clinicaInicial) {
        this.empresa = this.empresas.find(e=>e.value.id==(this.user.clinica_id?this.user.clinica_id:this.user.empresa_id)) || this.empresas[0]
     }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>